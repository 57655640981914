










































































import TableProvider from '@/api/TableProvider';
import { ILoadable, execLoad, makeLoadable } from '@/util/Loadable';
import { IMonsterWeightTableRow } from '@vincentzhang96/dv-dnt-table-interfaces/src/duck';
import { hasGrant } from '@/util/Utils';
import Vue from 'vue'
import { MonsterTableRow } from './types';

interface IData extends ILoadable<IMonsterWeightTableRow[]> {
}

export default Vue.extend({
    props: {
        monster: {
            type: Object as () => MonsterTableRow,
        } 
    },
    data(): IData {
        return {
            ...makeLoadable(),
        };
    },
    computed: {
        isDev(): boolean {
            return hasGrant('ref.pdev.duck');
        },
        region(): string {
            return this.$store.state.regionCode ?? 'duck';
        },
        authKey(): string {
            return encodeURIComponent(this.$store.getters.minervaKey);
        },
    },
    watch: {
        monster() {
            this.load();
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            await execLoad(this, async () => {
                const difficultyData = await TableProvider.getTableRowsMatching<IMonsterWeightTableRow>('virt.monsterweighttable', '_MonsterTableIndex', '==', this.monster.id);
                difficultyData.sort((a, b) => a._Difficulty - b._Difficulty);
                return difficultyData;
            });
        },
        stat(n: number): string {
            return n.toLocaleString(undefined, {
                useGrouping: true,
                maximumFractionDigits: 0,
            });
        }
    }
});
