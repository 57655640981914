















































import Vue from 'vue'
import Loader from "@/components/util/Loader.vue";
import UiString from '@/components/uistring/UiString.vue';
import TableProvider from '@/api/TableProvider';
import MonsterStats from './MonsterStats.vue';
import { execLoad, ILoadable, makeLoadable } from '@/util/Loadable';
import ActorInfo from '../general/ActorInfo.vue';
import { MonsterTableRow } from './types';
import MonsterBasicInfo from './MonsterBasicInfo.vue';
import MonsterSkills from './MonsterSkills.vue';
import MonsterImmunities from './MonsterImmunities.vue';


interface IData extends ILoadable<MonsterTableRow> {
}

export default Vue.extend({
    components: {
        Loader,
        UiString,
        MonsterStats,
        ActorInfo,
        MonsterBasicInfo,
        MonsterSkills,
        MonsterImmunities,
    },
    props: {
        monsterSlug: {
            type: String as () => string,
        } 
    },
    data(): IData {
        return {
            ...makeLoadable()
        };
    },
    computed: {
        monsterId(): number {
            if (this.monsterSlug) {
                const res = Number(this.monsterSlug);
                if (isNaN(res)) {
                    return -1;
                }
                
                return res;
            }
            
            return -1;
        },
    },
    watch: {
        monsterId() {
            this.load();
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            await execLoad(this, async () => {
                return await TableProvider.getTableRow<MonsterTableRow>('virt.monstertable', this.monsterId, undefined, {
                    uiresolve: ['_NameID._NameIDParam']
                });
            });
        },
    },
});
