















































import Vue from 'vue'
import { MonsterTableRow } from './types';

export default Vue.extend({
    props: {
        monster: {
            type: Object as () => MonsterTableRow,
        } 
    },
    methods: {
        grade(g: number) {
            switch (g) {
                case 0: return 'minion';
                case 1: return 'normal';
                case 2: return 'champion';
                case 3: return 'elite';
                case 4: return 'named';
                case 5: return 'boss';
                case 6: return 'boss HPx4';
                case 7: return 'nest boss';
                case 8: return 'nest boss HPx8';
                case 9: return 'nest boss HPx50';
                default: return `grade ${g}`;
            }
        },
    }
    
});
