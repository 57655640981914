














import TableProvider from '@/api/TableProvider';
import { execLoad, ILoadable, makeLoadable } from '@/util/Loadable';
import { IMonsterSkillTableRow } from '@vincentzhang96/dv-dnt-table-interfaces/src/duck';
import ResponsiveCardList from '@/components/util/ResponsiveCardList.vue';
import ResponsiveCardListEntry from '@/components/util/ResponsiveCardListEntry.vue';
import SkillStubLink from '@/components/skill/SkillStubLink.vue';
import Vue from 'vue'

interface IData extends ILoadable<IMonsterSkillTableRow[]> {
    skillFilter: string;
}

interface ISkillEntry {
    id: number;
    level: number;
}

export default Vue.extend({
    components: {
        ResponsiveCardList,
        ResponsiveCardListEntry,
        SkillStubLink,
    },
    props: {
        monsterSkillTableId: {
            type: Number as () => number,
        } 
    },
    data(): IData {
        return {
            ...makeLoadable(),
            skillFilter: '',
        };
    },
    computed: {
        skillList(): ISkillEntry[] {
            if (!this.value) {
                return [];
            }
            
            let arr = this.value;
            if (this.skillFilter) {
                const asNum = Number(this.skillFilter);
                if (!isNaN(asNum)) {
                    arr = this.value.filter((s) => s._SkillID === asNum);
                }
            }
            
            return arr.map((v) => ({
                id: v._SkillID,
                level: v._SkillLevel,
            }));
        },
    },
    watch: {
        monsterSkillTableId() {
            this.load();
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            await execLoad(this, async () => {
                return await TableProvider.getTableRowsMatching<IMonsterSkillTableRow>('virt.monsterskilltable', '_LearnSkillID', '==', this.monsterSkillTableId);
            });
        },
    },
});
