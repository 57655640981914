






























































































import * as SafeBase64 from 'urlsafe-base64';
import TableProvider from '@/api/TableProvider';
import { execLoad, ILoadable, makeLoadable } from '@/util/Loadable';
import { hasGrant } from '@/util/Utils';
import { FileTableResolvedTableRow } from '@vincentzhang96/dv-dnt-table-interfaces';
import { IActorTableRow } from '@vincentzhang96/dv-dnt-table-interfaces/src/duck';
import Vue from 'vue'

type ActorTableRow = FileTableResolvedTableRow<IActorTableRow, '_ActName'|'_AniName'|'_CamName'|'_SkinName'>;

enum ClassType {
    Warrior =  0,
    Archer,
    Soceress,
    Cleric,
    Academic,
    Kali,
    Assassin,
    Lencea,
    Machina,
    Reserved6,
    SimpleRush,
    Normal,
    Defense,
    Disturbance,
    Range,
    Rush,
    Giant,
    Magic,
    Trap,
    Cannon,
    NoAggro,
    NoAggroTrap,
    GoldMonster,
    GiantDefence,

    NESTBOSS = 69,
    Basilisk = 70,
    Beholder,

    BlackDragon, 
    GoldDragon,
    Manticore,
    Cerberos,
    SeaDragon,
    LotusGolem,
    GreenDragon,
    LotusGolemHitbox,
    EndOfMonster,

    Vehicle = 80,
    Pet,

    Npc = 100,
    
    PropActor = 150,
    ActorTypeEnum_Amount,

    Vandar = 201,
    PlayerActor,
}

interface IData extends ILoadable<ActorTableRow> {
}

export default Vue.extend({
    data(): IData {
        return {
            ...makeLoadable(),
        };
    },
    props: {
        actorId: {
            type: Number as () => number,
        },
    },
    watch: {
        actorId() {
            this.load();
        }
    },
    computed: {
        isDev(): boolean {
            return hasGrant('ref.pdev.duck');
        },
        region(): string {
            return this.$store.state.regionCode ?? 'duck';
        },
        authKey(): string {
            return encodeURIComponent(this.$store.getters.minervaKey);
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            await execLoad(this, async () => {
                return await TableProvider.getTableRow<ActorTableRow>('virt.actortable', this.actorId, undefined, {
                    fileresolve: ['_ActName', '_AniName', '_CamName', '_SkinName'],
                });
            });
        },
        pressType(v: number) {
            switch (v) {
                case 0: return `Circle`;
                case 1: return `Capsule`;
                case 2: return `Circle if different`
                default: return `Unknown type ${v}`;
            }
        },
        material(v: number) {
            switch (v) {
                case 0: return 'flesh';
                case 1: return 'metal';
                case 2: return 'solid';
                case 3: return 'wood';
                default: return `material ${v}`;
            }
        },
        classType(v: number) {
            return ClassType[v] ?? `class ${v}`;
        },
        safeUrl(url: string): string {
            return SafeBase64.encode(Buffer.from(url, 'utf-8'));
        }
    }
    
})
