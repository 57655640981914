





















import Vue from 'vue'
import { MonsterTableRow } from './types';
import { Blows } from '@/models/skills/IStateBlow';

interface IImmunity {
    status: number;
    resistRate: number;
    durationReductionRate: number;
}

export default Vue.extend({
    props: {
        monster: {
            type: Object as () => MonsterTableRow,
        } 
    },
    computed: {
        immunities(): IImmunity[] {
            const statuses = this.monster._ImmunedStateBlow.split(';').filter((v) => v.length).map((v) => Number(v));
            const resistRates = this.monster._ImmunePercent.split(';').map((v) => Number(v));
            const durationReductionRates = this.monster._ImmuneReduceTime.split(';').map((v) => Number(v));
            
            return statuses.map((v, i) => ({
                status: v,
                resistRate: resistRates[i] ?? 0,
                durationReductionRate: durationReductionRates[i] ?? 0,
            })).sort((a, b) => a.status - b.status);
        }
    },
    methods: {
        statusName(status: number): string {
            // todo
            const blow = Blows[status];
            if (blow) {
                return blow.name;
            }
            
            return 'unknown';
        }
    }
    
});
