export interface ILoadable<T> {
    loading: boolean;
    loadNonce: number;
    error: string;
    value: T|null;
}

export function makeLoadable<T>(): ILoadable<T> {
    return {
        loading: true,
        loadNonce: 0,
        error: '',
        value: null,
    };
}

export async function execLoad<T>(loadable: ILoadable<T>, task: () => Promise<T>) {
    loadable.loading = true;
    loadable.error = '';
    ++loadable.loadNonce;
    const nonce = loadable.loadNonce;
    try {
        const value = await task();
        if (loadable.loadNonce === nonce) {
            loadable.value = value;
        } else {
            console.warn(`Dropping result due to nonce change`, new Error());
        }
    } catch (error) {
        loadable.error = String(error);
    } finally {
        loadable.loading = false;
    }
}
